import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  return (
    <Wrapper>
      <Block>
        Copyright &copy; {new Date().getFullYear()}.{'  '}
        {site.siteMetadata.title}.{'  '}
        All rights reserved.
      </Block>
      <Block>Created & Maintained By: {site.siteMetadata.author}</Block>
    </Wrapper>
  );
};

export default Footer;

const Block = styled.div`
  display: flex;
  padding: 0.5rem;
  text-align: center;
  line-height: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1 1 auto;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 0.75rem;
  width: 100vw;
`;
