import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <MainContainer>
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
    </MainContainer>
  );
};

export default Layout;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 10rem;
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
`;
